import "../style/Footer.css"

function Footer(props) {
    return (<footer className="footer Basic">
        <p className="Gruppo">Copyright © 2024 YAMADA TAKAHIRO WEB CREATION</p>
        <div className="hideall">
            <p>No set © 2023 YAMADA TAKAHIRO WEB C 隆広 サイトを開く</p>
            <p className="Basic">Basic © 2023 YAMADA TAKAHIRO WEB C 隆広 サイトを開く</p>
            <p className="Gruppo">Gruppo © 2023 YAMADA TAKAHIRO WEB C 隆広 サイトを開く</p>
            <p className="Vollkorn">Vollkorn © 2023 YAMADA TAKaushanKAHIRO WEB C 隆広 サイトを開く</p>
            <p className="Allura">Allura © 2023 YAMADA TAKAHIRO WEB C 隆広 サイトを開く</p>
            <p className="Kaushan">Kaushan © 2023 YAMADA TAKAHIRO WEB C 隆広 サイトを開く</p>
            <p className="Parisienne">Parisienne © 2023 YAMADA TAKAHIRO WEB C 隆広 サイトを開く</p>
            <p className="Yuji">Yuji © 2023 YAMADA TAKAHIRO WEB C 隆広 サイトを開く</p>
            <p className="ZenAntique">ZenAntique © 2023 YAMADA TAKAHIRO WEB C 隆広 サイトを開く</p>
            <p className="ZenDots">ZenDots © 2023 YAMADA TAKAHIRO WEB C 隆広 サイトを開く</p>
            <p className="Shippori">Shippori © 2023 YAMADA TAKAHIRO WEB C 隆広 サイトを開く</p>
            <p className="ethnocentric">ethnocentric © 2023 YAMADA TAKAHIRO WEB C 隆広 サイトを開く</p>
            <p className="Cocogoose">Cocogoose © 2023 YAMADA TAKAHIRO WEB C 隆広 サイトを開く</p>
            <p className="PetrovSans">PetrovSans © 2023 YAMADA TAKAHIRO WEB C 隆広 サイトを開く</p>
        </div>
    </footer>);
}

export default Footer;